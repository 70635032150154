<template>
    <div class="modal-edit-style-type flex items-start">
        <div class="flex-wrap" @click="$emit('close')">
            <i class="material-icons">close</i>
        </div>
        <div class="page-header flex-wrap">
            <div class="title" v-html="$translate(`MODAL_${options.key.toUpperCase()}_TYPE`).replace(/\n/, '<br>')" />
            <div
                v-if="options.key === 'region'"
                v-html="$translate('MANAGER_SELECT_REGION_WIDE')"
                class="add-intro m-t-8 f-13 c-grey-07"
            />
        </div>
        <div class="types" :class="{ 'm-t-0': options.key === 'region' }">
            <div
                @click="select(item)"
                class="item"
                :class="{ selected: item.$$selected }"
                :key="item.title"
                v-for="item in items"
            >
                <span v-html="item.title" />
                <i v-if="options.key === 'region'" class="material-icons" :class="{ selected: item.$$selected }">{{
                    item.$$selected ? 'check_box' : 'check_box_outline_blank'
                }}</i>
            </div>
        </div>
        <BottomButton v-if="options.key === 'region'" :label="$translate('NEXT')" @click="onSelectRegions" />
    </div>
</template>

<script>
export default {
    name: 'ModalEditStyleType',
    props: ['options'],
    data: () => ({
        items: [],
    }),
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const gender = this.me.gender === 0 ? 'male' : 'female'
            const types = this.$vbConstants.types[this.options.key]
            this.items = (this.options.key === 'height' ? types[gender] : types).map(o => {
                this.$set(o, '$$selected', o.value === (this.me.style || {})[`${this.options.key}_type`])
                return o
            })
        },
        select(item) {
            if (this.options.key === 'region') {
                item.$$selected = !item.$$selected
            } else {
                this.items.forEach(o => {
                    o.$$selected = o.value === item.value
                })
                setTimeout(() => this.$emit('close', item), 200)
            }
        },
        onSelectRegions() {
            if (this.options.key !== 'region') return

            const regionArr = []
            this.items.forEach(item => {
                if (item.$$selected) regionArr.push(item)
            })
            setTimeout(() => this.$emit('close', regionArr), 200)
        },
    },
}
</script>
